
  // src/context/AuthContext.tsx
  import React, { createContext, useContext, useState, useEffect } from 'react';
  import { AuthState, LoginCredentials } from '../types/auth';
  import { API_BASE_URL } from '../utils/api'; 
  
  interface AuthContextType extends AuthState {
    login: (credentials: LoginCredentials) => Promise<void>;
    logout: () => void;
  }
  
  const AuthContext = createContext<AuthContextType | undefined>(undefined);
  
  export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [auth, setAuth] = useState<AuthState>({
      token: localStorage.getItem('token'),
      isAuthenticated: !!localStorage.getItem('token')
    });
  
    const login = async (credentials: LoginCredentials) => {
        console.log('Starting login with API_BASE_URL:', API_BASE_URL);
        const loginUrl = `${API_BASE_URL}/auth/login`;
        console.log('Using login URL:', loginUrl);
        
        try {
          const response = await fetch(loginUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
          });
      
          console.log('Got response:', response.status);
          const data = await response.json();
      
          if (!data.success) {
            throw new Error(data.error || 'Login failed');
          }
      
          localStorage.setItem('token', data.data.token);
          setAuth({ token: data.data.token, isAuthenticated: true });
        } catch (error) {
          throw new Error(error instanceof Error ? error.message : 'Login failed');
        }
      };
  
    const logout = () => {
      localStorage.removeItem('token');
      setAuth({ token: null, isAuthenticated: false });
    };
  
    return (
      <AuthContext.Provider value={{ ...auth, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  };
  
