import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/api';
import TweetCard from './TweetCard';
import QueueCountdown from './QueueCountdown';
import { Tweet } from '../types/tweet';
import ConfigPanel from './ConfigPanel';


  export const TweetDashboard: React.FC = () => {
    const [tweets, setTweets] = useState<Tweet[]>([]);
    const [newTweet, setNewTweet] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

  const fetchTweets = async () => {
    try {
      const response = await apiRequest<{ success: boolean; data: Tweet[] }>('/api/tweets/all');
      if (response.success) {
        setTweets(response.data);
      }
    } catch (err) {
      setError('Failed to fetch tweets');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (id: string, status: 'approved' | 'rejected') => {
    try {
      await apiRequest(`/api/tweets/${id}/${status === 'approved' ? 'approve' : 'reject'}`, {
        method: 'POST',
      });
      fetchTweets();
    } catch (err) {
      setError(`Failed to ${status} tweet`);
    }
  };

  const handleDeleteTweet = async (id: string) => {
    try {
      await apiRequest(`/api/tweets/${id}`, {
        method: 'DELETE',
      });
      fetchTweets();
    } catch (err) {
      setError('Failed to delete tweet');
    }
  };

  const handleAddTweet = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTweet.trim()) return;

    try {
      await apiRequest('/api/tweets', {
        method: 'POST',
        body: JSON.stringify({ content: newTweet }),
      });
      setNewTweet('');
      fetchTweets();
    } catch (err) {
      setError('Failed to add tweet');
    }
  };

  const handlePostNow = async (id: string) => {
    if (!window.confirm('Are you sure you want to post this tweet now?')) {
      return;
    }

    try {
      await apiRequest(`/api/tweets/${id}/post-now`, {
        method: 'POST',
      });
      fetchTweets(); // Refresh the list
    } catch (err) {
      setError('Failed to post tweet immediately');
    }
  };

  const handlePrioritize = async (id: string) => {
    try {
      await apiRequest(`/api/tweets/${id}/priority`, {
        method: 'POST',
        body: JSON.stringify({ priority: 1 }), // Increment priority
      });
      fetchTweets();
    } catch (err) {
      setError('Failed to prioritize tweet');
    }
  };


  useEffect(() => {
    fetchTweets();
    const interval = setInterval(fetchTweets, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <div className="flex justify-center p-8">Loading tweets...</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-[1fr,auto] gap-6">
        {/* Main content column */}
        <div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center">
              {error}
            </div>
          )}
  
          <div className="flex justify-end mb-4">
            <QueueCountdown />
          </div>
  
          {/* Post Input Card */}
          <div className="tweet-card">
            <div className="tweet-card-inner">
              <form onSubmit={handleAddTweet} className="compose-tweet-form">
                <div className="flex gap-3">
                  <div className="flex-shrink-0">
                    <div className="profile-image">\</div>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      value={newTweet}
                      onChange={(e) => setNewTweet(e.target.value)}
                      placeholder="What should ASYM be talking about?"
                      className="compose-tweet-input"
                    />
                    <div className="compose-tweet-actions">
                      <button type="submit" className="compose-tweet-button">
                        Add Tweet
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
  
          {/* Tweets List */}
          <div className="tweets-container space-y-6">
            {/* Approved Tweets Section */}
            <div className="tweet-section">
              <h2 className="text-xl font-bold mb-3 text-gray-800">Ready to Post</h2>
              {tweets
                .filter(tweet => tweet.status === 'approved')
                .map((tweet) => (
                  <TweetCard
                    key={tweet.id}
                    tweet={tweet}
                    onApprove={undefined}
                    onReject={undefined}
                    onDelete={() => handleDeleteTweet(tweet.id)}
                    onPostNow={() => handlePostNow(tweet.id)}
                    onPrioritize={() => handlePrioritize(tweet.id)}
                  />
                ))}
            </div>
  
            {/* Pending Tweets Section */}
            <div className="tweet-section">
              <h2 className="text-xl font-bold mb-3 text-gray-800">Pending Review</h2>
              {tweets
                .filter(tweet => tweet.status === 'pending')
                .map((tweet) => (
                  <TweetCard
                    key={tweet.id}
                    tweet={tweet}
                    onApprove={() => handleStatusUpdate(tweet.id, 'approved')}
                    onReject={() => handleStatusUpdate(tweet.id, 'rejected')}
                    onDelete={() => handleDeleteTweet(tweet.id)}
                    onPostNow={undefined}
                    onPrioritize={undefined}
                  />
                ))}
            </div>
  
            {/* Posted Tweets Section */}
            <div className="tweet-section">
              <h2 className="text-xl font-bold mb-3 text-gray-800">Posted</h2>
              {tweets
                .filter(tweet => tweet.status === 'posted')
                .map((tweet) => (
                  <TweetCard
                    key={tweet.id}
                    tweet={tweet}
                    onDelete={() => handleDeleteTweet(tweet.id)}
                    onApprove={undefined}
                    onReject={undefined}
                    onPostNow={undefined}
                    onPrioritize={undefined}
                  />
                ))}
            </div>
          </div>
        </div>
  
        {/* Config panel column */}
        <div className="sticky top-4">
          <ConfigPanel />
        </div>
      </div>
    </div>
  );
};