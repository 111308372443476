import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/api';

interface ConfigValues {
  generation_interval_minutes: string;
  post_interval_minutes: string;
}

const ConfigPanel: React.FC = () => {
  const [configs, setConfigs] = useState<ConfigValues | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>('');

  const fetchConfigs = async () => {
    try {
      const response = await apiRequest<{ success: boolean; data: Record<string, string> }>('/api/config');
      if (response.success) {
        const configValues: ConfigValues = {
          generation_interval_minutes: response.data.generation_interval_minutes || '1',
          post_interval_minutes: response.data.post_interval_minutes || '5'
        };
        setConfigs(configValues);
      }
    } catch (err) {
      setError('Failed to fetch configurations');
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  const handleEdit = (key: string, currentValue: string) => {
    setIsEditing(key);
    setEditValue(currentValue);
  };

  const handleSave = async (key: string) => {
    try {
      await apiRequest(`/api/config/${key}`, {
        method: 'POST',
        body: JSON.stringify({ value: editValue }),
      });
      setIsEditing(null);
      fetchConfigs();
    } catch (err) {
      setError('Failed to update configuration');
    }
  };

  const handleCancel = () => {
    setIsEditing(null);
    setEditValue('');
  };

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!configs) {
    return (
      <div className="bg-gray-50 p-4 rounded-md">
        <p className="text-gray-600">Loading configurations...</p>
      </div>
    );
  }

  const configLabels: Record<string, string> = {
    generation_interval_minutes: 'Tweet Generation Interval (minutes)',
    post_interval_minutes: 'Post Interval (minutes)'
  };

  return (
    <div className="bg-white p-4 rounded-md shadow-sm w-64">
      <h2 className="text-lg font-semibold mb-4">Configuration</h2>
      <div className="space-y-4">
        {Object.entries(configs).map(([key, value]) => (
          <div key={key} className="space-y-1">
            <label className="text-sm text-gray-600">{configLabels[key]}</label>
            {isEditing === key ? (
              <div className="flex space-x-2">
                <input
                  type="number"
                  min="1"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  className="w-20 px-2 py-1 border rounded text-sm"
                />
                <button
                  onClick={() => handleSave(key)}
                  className="text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="text-xs px-2 py-1 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <span className="font-medium">{value}</span>
                <button
                  onClick={() => handleEdit(key, value)}
                  className="text-xs px-2 py-1 text-blue-500 hover:text-blue-600"
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfigPanel;