import React from 'react';
import { Tweet } from '../types/tweet';

interface TweetCardProps {
    tweet: Tweet;
    onApprove?: () => void;
    onReject?: () => void;
    onDelete?: () => void;
    onPostNow?: () => void;
    onPrioritize?: () => void;
  }

  const TweetCard: React.FC<TweetCardProps> = ({
    tweet,
    onApprove,
    onReject,
    onDelete,
    onPostNow,
    onPrioritize
  }) => {
    return (
      <div className="tweet-card">
        <div className="tweet-card-inner">
          <div className="flex gap-3">
            <div className="flex-shrink-0">
              <div className="profile-image">\</div>
            </div>
            
            <div className="flex-1">
              <div className="tweet-header">
                <span className="tweet-name">ASYM</span>
                <span className="tweet-handle">@asym41b07</span>
                <span className="tweet-handle">·</span>
                <div className="tweet-metadata">
                  {tweet.post_immediately && (
                    <span className="posting-badge">Posting Immediately</span>
                  )}
                  {tweet.priority > 0 && (
                    <span className="priority-badge">Priority {tweet.priority}</span>
                  )}
                  {tweet.approved_at && (
                    <span className="text-sm text-gray-500">
                      Approved {new Date(tweet.approved_at).toLocaleString()}
                    </span>
                  )}
                </div>
              </div>
              
              <div className="tweet-content">
                {tweet.content}
              </div>
              
              <div className="action-buttons">
                {tweet.status === 'pending' && (
                    <>
                    {onApprove && (
                        <button onClick={onApprove} className="btn btn-approve">
                        Approve
                        </button>
                    )}
                    {onReject && (
                        <button onClick={onReject} className="btn btn-reject">
                        Reject
                        </button>
                    )}
                    </>
                )}
                
                {tweet.status === 'approved' && (
                    <>
                    {onPostNow && (
                        <button onClick={onPostNow} className="btn btn-post-now">
                        Post Now
                        </button>
                    )}
                    {onPrioritize && (
                        <button onClick={onPrioritize} className="btn btn-prioritize">
                        Prioritize
                        </button>
                    )}
                    </>
                )}
                
                {onDelete && (
                    <button onClick={onDelete} className="btn btn-delete">
                    Delete
                    </button>
                )}
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TweetCard;