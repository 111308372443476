import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/api';

interface QueueStatus {
  lastPostTime: number | null;
  postIntervalMinutes: number;
}

export const QueueCountdown: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [queueStatus, setQueueStatus] = useState<QueueStatus | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Fetch queue status
  const fetchQueueStatus = async () => {
    try {
      const response = await apiRequest<{ success: boolean; data: QueueStatus }>('/api/queue-status');
      if (response.success) {
        setQueueStatus(response.data);
      }
    } catch (err) {
      setError('Failed to fetch queue status');
    }
  };

  // Add a function to fetch the current config
  const fetchCurrentConfig = async () => {
    try {
      const response = await apiRequest<{ success: boolean; data: Record<string, string> }>('/api/config');
      if (response.success && queueStatus) {
        // Update queue status with new interval if it changed
        const newInterval = parseInt(response.data.post_interval_minutes);
        if (newInterval !== queueStatus.postIntervalMinutes) {
          setQueueStatus(prev => prev ? {
            ...prev,
            postIntervalMinutes: newInterval
          } : null);
        }
      }
    } catch (err) {
      console.error('Failed to fetch current config:', err);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchQueueStatus();
    
    // Fetch queue status every minute
    const statusInterval = setInterval(fetchQueueStatus, 60000);
    
    // Also check config every 10 seconds for changes
    const configInterval = setInterval(fetchCurrentConfig, 10000);
    
    return () => {
      clearInterval(statusInterval);
      clearInterval(configInterval);
    };
  }, []);

  useEffect(() => {
    if (!queueStatus) return;

    const calculateTimeLeft = () => {
      const now = Date.now();
      if (!queueStatus.lastPostTime) {
        // If no posts yet, can post immediately
        return 0;
      }
      
      const nextPossiblePost = queueStatus.lastPostTime + (queueStatus.postIntervalMinutes * 60 * 1000);
      const remaining = nextPossiblePost - now;
      return Math.max(0, remaining);
    };

    // Calculate initial time left
    setTimeLeft(calculateTimeLeft());

    // Update countdown every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [queueStatus]); // This will re-run when queueStatus changes

  const formatTime = (ms: number): string => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  if (error) {
    return (
      <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1">
        <span className="text-red-600">Error loading queue status</span>
      </div>
    );
  }

  if (timeLeft === null || !queueStatus) {
    return (
      <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1">
        <span className="text-gray-600">Loading queue status...</span>
      </div>
    );
  }

  return (
    <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1">
      <span className="font-medium text-gray-600">
        {timeLeft === 0 
          ? "Ready to post" 
          : `Next post possible in: ${formatTime(timeLeft)}`}
      </span>
    </div>
  );
};

export default QueueCountdown;